<template>
  <div class="policy-track">
    <div class="top_banner">
      <img src="@/assets/images/news/banner_news_n.jpg" alt="" />
      <div class="top_title">
        <h3>关注新闻动态 了解行业资讯</h3>
        <!-- <p>Follow News - Learn Industry News</p> -->
      </div>
    </div>
    <div class="dot_area">
      <img src="@/assets/images/news/dots.png" alt="" />
      <img src="@/assets/images/news/dots.png" alt="" />
    </div>
    <ul class="menu_news">
      <li @click="$router.push('/news/group-dynamics')">
        <div class="title">
          <h3>集团动态</h3>
          <!-- <p>Group Dynamics</p> -->
        </div>
      </li>
      <li @click="$router.push('/news/trade-news')">
        <div class="title">
          <h3 class="color-primary">行业新闻</h3>
          <!-- <p>Industry News</p> -->
        </div>
      </li>
      <li @click="$router.push('/news/policy-track')">
        <div class="title">
          <h3>政策追踪</h3>
          <!-- <p>Policy Tracking</p> -->
        </div>
      </li>
      <li @click="$router.push('/news/public-benefit')">
        <div class="title">
          <h3>晓羊公益行</h3>
          <!-- <p>Xiaoyang Charity Line</p> -->
        </div>
      </li>
    </ul>
    <ul class="content">
      <li v-for="item in dataList" :key="item.id">
        <div class="pic">
          <img :src="item.header_image" alt="" />
        </div>
        <div class="main">
          <div>
            <div class="h3">{{ item.title }}</div>
            <div
              v-html="item.content.replace(/<[^>]+>/g, '')"
              class="content"
            ></div>
            <!-- <p>{{ $moment(item.publish_at * 1000).format('YYYY年M月DD日') }}</p> -->
            <div class="operate_btn pc">
              <el-button type="primary" @click="goDetail(item.id)"
                >了解更多 >
              </el-button>
            </div>
            <div class="operate_btn mobile">
              <el-button type="primary" size="small" @click="goDetail(item.id)"
                >了解更多 >
              </el-button>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'TradeNews',
  data() {
    return {
      dataList: [],
    }
  },
  methods: {
    getDataList() {
      this.$axios
        .post('/news/list', {
          category_id: 5,
          publish_status: 1,
        })
        .then(res => {
          console.log(res)
          this.dataList = res.list
        })
    },
    goDetail(id) {
      this.$router.push({
        path: '/news/detail',
        query: {
          id: id,
        },
      })
    },
    getChinese(content) {
      var reg =
        /[\u4e00-\u9fa5|\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/g
      let names = content.match(reg)
      let result
      if (names) {
        result = names.join('')
      } else {
        result = ''
      }
      return result
    },
  },
  mounted() {
    this.$store.commit('setPcHeaderIsTransparent', 'transparent')
    this.getDataList()
  },
}
</script>

<style lang="scss" scoped>
%policy {
  .policy-track {
    .top_banner {
      position: relative;
      img {
        width: 100%;
      }
      .top_title {
        position: absolute;
        left: 0;
        @include px2vw('left', 268);
        width: auto;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        color: $--color-white;
        h3 {
          margin-top: 0;
          @include px2vw('font-size', 52);
          margin-bottom: 0 !important;
        }
        p {
          @include px2vw('font-size', 32);
          margin-bottom: 0;
          @include px2vw('margin-top', 20);
        }
        &:after {
          content: '';
          position: absolute;
          left: -34px;
          top: 50%;
          transform: translateY(-50%);
          height: 100%;
          width: 6px;
          background-color: $--color-white;
        }
      }
    }
    .dot_area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 40%;
      }
    }
    .menu_news {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 100px;
      li {
        text-align: center;
        cursor: pointer;
        h3 {
          margin: 0;
          @include px2vw('padding-left', 80);
          @include px2vw('padding-right', 80);
          @include px2vw('font-size', 36);
          border-right: 1px solid #d8d8d8;
          color: #333;
        }
        p {
          @include px2vw('font-size', 16);
        }
        &:hover h3 {
          color: $--color-primary;
        }
      }
      li:last-child h3 {
        border: none;
      }
    }
    .content {
      width: 90%;
      max-width: 1320px;
      margin: 0 auto;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 60px 0;
        // padding: 60px 0 0 !important;
        padding: 60px;
        box-shadow: 0px 25px 55px 0px rgba(142, 142, 142, 0.19);
        > div {
          width: 54%;
          display: flex;
          align-items: center;
          img {
            width: 100%;
          }
          > div {
            h3 {
              font-size: 32px;
              margin-top: 0;
              margin-bottom: 45px;
              line-height: 50px;
            }

            > div {
              font-size: 18px;
              width: 100%;
              // height: 100px;
              color: #666;
              text-align: justify;
              line-height: 31px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .h3 {
              font-size: 32px;
              font-weight: 600;
              color: #333333;
              line-height: 50px;
              margin-bottom: 20px;
            }
            button {
              margin-top: 40px;
            }
            > p {
              font-size: 18px;
              color: #999;
            }
          }
        }
        .main {
          width: 50%;
        }
        .pic {
          width: 40%;
          min-height: 350px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f2f2f2;
        }
        > img {
          width: 42%;
        }
        &:nth-child(2n-1) {
          flex-direction: row-reverse;
        }
      }
    }
    @media screen and (max-width: 1060px) {
      .content {
        li {
          display: block;
          padding: 40px 10px;
          img {
            width: 100%;
            margin-bottom: 40px;
          }
          > div {
            h3 {
              text-align: center;
            }
            width: 100%;
            padding: 0;
            .operate_btn {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
.view-pc {
  @extend %policy;
  .content {
    li {
      .main {
      }
      > img {
        width: 42%;
      }
      &:nth-child(2n-1) {
        flex-direction: row-reverse;
      }
    }
  }
}
.view-mobile {
  @extend %policy;
  .policy-track {
    margin-top: 44px;
    .top_banner {
      .top_title {
        h3 {
          font-size: 18px;
        }
        p {
          font-size: 14px;
        }
      }
    }
    .content {
      width: 95%;
      display: flex;
      flex-wrap: wrap;
      li {
        padding: 10px;
        margin: 0 auto 30px;
        width: calc((100% - 50px) / 2);
        &:nth-child(2n) {
          margin-left: 10px;
        }
        img {
          margin-bottom: 0;
        }
        .pic {
          width: 100%;
          display: inherit;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
          height: 90px;
          min-height: 90px;
          background-color: #f2f2f2;
          overflow: hidden;
          margin-bottom: 10px;
        }
        .main {
          width: 100%;
          > div {
            h3 {
              margin-bottom: 10px;
              font-size: 14px !important;
              overflow: hidden;
              line-height: 22px !important;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .h3 {
              margin-bottom: 10px;
              margin-top: 10px;
              width: 100%;
              min-height: 46px;
              font-size: 14px !important;
              line-height: 22px !important;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
              font-weight: bold;
              padding: 0;
              text-align: center;
            }
          }
        }
        > div {
          > div {
            h3 {
              font-size: 18px !important;
              // padding: 0 5px;
              // margin-top: 0;
              // margin-bottom: 20px;
              line-height: 30px;
            }
            .content {
              display: none;
            }
            > div {
              font-size: 14px;
              width: calc(100% - 20px);
              // height: 100px;
              color: #666;
              text-align: justify;
              line-height: 24px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              font-size: 14px;
              padding: 0 10px;
              // display: none;
            }
            button {
              margin-top: 10px;
            }
          }
        }
      }
    }
    .menu_news {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 30px;
      // overflow-x: scroll;
      li {
        width: 50%;
        margin-bottom: 10px;
        // width: max-content;
        // min-width: 120px;
        h3 {
          font-size: 16px;
          color: #333;
        }
        p {
          font-size: 10px;
        }
      }
    }
  }
}
</style>
